import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as calendrierxzbinl0to0Meta } from "/opt/buildhome/repo/pages/app/calendrier.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as cameraGxFio15UzsMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/camera.vue?macro=true";
import { default as indexrEg444kN8wMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HxQxjzxhjNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "app-calendrier",
    path: "/app/calendrier",
    component: () => import("/opt/buildhome/repo/pages/app/calendrier.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/opt/buildhome/repo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "app-theme-date-camera",
    path: "/app/theme/:date()/camera",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/camera.vue").then(m => m.default || m)
  },
  {
    name: "app-theme-date",
    path: "/app/theme/:date()",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]